import BSN from "bootstrap.native";

document.querySelectorAll('[data-toggle="_modal"]').forEach(function (elt) {
    elt.addEventListener("click", function (e) {
        const modal = new BSN.Modal(
            document.querySelector(e.target.dataset.target)
        );
        modal.show();
    });
});

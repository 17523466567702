import Splide from "@splidejs/splide";
import ReadSmore from "read-smore/dist/index.modern.js";

const readMores = document.querySelectorAll(".js-read-smore");
ReadSmore(readMores).init();

document.querySelectorAll(".splide-guide").forEach((el) => {
    var $count = 3;
    if (window.matchMedia("(max-width: 767px)").matches) {
        $count = 1;
    }
    if (el.querySelectorAll(".splide__slide").length > $count) {
        new Splide(el, {
            type: "slide",
            perPage: $count,
            // speed: 600,
            // pagination: false,
            arrows: el.querySelectorAll(".splide__slide").length > $count,
            autoWidth: true,
            gap: "1rem",
            // padding: "20%",
            pagination: false,
        }).mount();
        var $guide__item__figure_height = document.querySelector(
            ".splide-guide .guide__item__figure"
        ).offsetHeight;
        document
            .querySelectorAll(".splide-guide .splide__arrow")
            .forEach((el) => {
                el.style.height = $guide__item__figure_height + "px";
            });
    } else {
        el.classList.remove("splide");
        el.querySelector(".splide__arrows").remove();
    }
});

if (document.location.hash) {
    setTimeout(() => {
        const targetElement = document.querySelector(document.location.hash);
        if (targetElement)
            targetElement.scrollIntoView({
                alignToTop: true,
                behavior: "smooth",
            });
    }, 500);
}

document.querySelectorAll(".splide-guide-linked").forEach((el) => {
    var $count = 3;
    if (window.matchMedia("(max-width: 767px)").matches) {
        $count = 1;
    }
    if (el.querySelectorAll(".splide__slide").length > $count) {
        new Splide(el, {
            type: "slide",
            perPage: $count,
            // speed: 600,
            // pagination: false,
            arrows: el.querySelectorAll(".splide__slide").length > $count,
            autoWidth: true,
            gap: "1rem",
            // padding: "20%",
            pagination: false,
        }).mount();
        var $guide__item__figure_height = document.querySelector(
            ".splide-guide-linked picture"
        ).offsetHeight;
        document
            .querySelectorAll(".splide-guide-linked .splide__arrow")
            .forEach((el) => {
                el.style.height = $guide__item__figure_height + "px";
            });
    } else {
        el.classList.remove("splide");
        el.querySelector(".splide__arrows").remove();
    }
});

var throttle = require("lodash.throttle");

var video = document.querySelector(".jwplayer");
let videoSources = null;
async function setupSources(video) {
    if (video) {
        videoSources = await Promise.all(
            Array.from(video.querySelectorAll("source")).map(async (source) => {
                if (source.dataset.src) {
                    const response = await fetch(source.dataset.src, {
                        method: "POST",
                    });
                    video_url = await response.json();
                } else {
                    video_url = source.getAttribute("src");
                }
                return {
                    file: video_url,
                };
            })
        );
        setupPlayer(video);
    }
}

const setupPlayer = (video) => {
    console.log("setupPlayer");
    if (video) {
        // for live stream without a video url yet
        if (video.querySelectorAll("source").length) {
            let mediaid = video.dataset.uuid;

            const match_lang =
                document.location.hostname.match(/^(?<lang>\w{2})\./);
            if (match_lang) mediaid = match_lang.groups.lang + "-" + mediaid;

            if (document.location.hostname.match(/prajnaonline\.org/))
                mediaid = "prod-" + mediaid;

            let elapsed = window.viewer_component
                ? parseInt(window.viewer_component.get("elapsed"))
                : 0;

            console.log({ elapsed });

            if (video.querySelector("source").dataset.duration == elapsed)
                elapsed = 0;

            let config = {
                playlist: [
                    {
                        preload: video.dataset.preload == "auto",
                        image:
                            "https://" +
                            document.location.hostname +
                            video.getAttribute("poster"),
                        title: video.dataset.title,

                        sources: videoSources,
                        starttime: elapsed,
                    },
                ],
                autostart: video.hasAttribute("autoplay"),
                mute: video.hasAttribute("muted"),
                controls: true, // video.hasAttribute("controls"),
                displaytitle: false,
                stretching: "uniform", // "fill", //"uniform",
                ga: {
                    label: "title",
                },
            };

            if (!document.location.hostname.match(/prajnaonline\.org/))
                config.floating = {
                    dismissible: true,
                };

            console.log(window.jwplayer);

            let jwplayer = window
                .jwplayer(video.getAttribute("id"))
                .setup(config);
            if (window.viewer_component) {
                jwplayer
                    .on("play", (e) => {
                        window.viewer_component.set("playing", true);
                        if (
                            document.querySelector(
                                ".btn-primary-update-audience"
                            )
                        )
                            document
                                .querySelector(".btn-primary-update-audience")
                                .click();
                    })
                    .on("pause", (e) => {
                        window.viewer_component.set("playing", false);
                    })
                    .on(
                        "time",
                        throttle((e) => {
                            window.viewer_component.set(
                                "elapsed",
                                parseInt(e.position)
                            );
                        }, 5000)
                    );
            }
            if (document.querySelector(".bt-play")) {
                const params = new URLSearchParams(window.location.search);
                if (params.get("play")) {
                    jwplayer.on("ready", function (e) {
                        setTimeout(() => {
                            document
                                .querySelector(".focus__inner--single .row")
                                .click();
                        }, 50);
                    });
                }

                document
                    .querySelector(".focus__inner--single .row")
                    .addEventListener("click", function (event) {
                        jwplayer.play();
                        document
                            .querySelector(".focus__inner")
                            .classList.add("hidden");
                        event.preventDefault();
                    });

                jwplayer
                    .on("pause", function (e) {
                        document
                            .querySelector(".focus__inner")
                            .classList.remove("hidden");
                        gtag("event", "video_pause", {
                            video_id: video.dataset.uuid,
                            video_name: video.dataset.title,
                            elapsed: jwplayer.getPosition(),
                        });
                    })
                    .on("play", function (e) {
                        document
                            .querySelector(".focus__inner")
                            .classList.add("hidden");

                        gtag("event", "video_play", {
                            video_id: video.dataset.uuid,
                            video_name: video.dataset.title,
                            elapsed: jwplayer.getPosition(),
                        });
                        if (
                            document.querySelector(
                                ".btn-primary-update-audience"
                            )
                        )
                            document
                                .querySelector(".btn-primary-update-audience")
                                .click();
                    })
                    .on("complete", (e) => {
                        window.viewer_component.set("elapsed", 0);
                        gtag("event", "video_complete", {
                            video_id: video.dataset.uuid,
                            video_name: video.dataset.title,
                            elapsed: jwplayer.getPosition(),
                        });
                        if (video.dataset.next)
                            document.location = video.dataset.next + "?play=1";
                    })
                    .on(
                        "time",
                        throttle((e) => {
                            gtag("event", "video_playing", {
                                video_id: video.dataset.uuid,
                                video_name: video.dataset.title,
                                elapsed: jwplayer.getPosition(),
                            });
                        }, 10000)
                    );
            }
            // document.getElementsByClassName("bt-play")[0].click();
            // jwplayer().pause();
        }
    }
};
setupSources(video);
// window.onload = (event) => setupPlayer(video);

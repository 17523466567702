var jstz = require("jstimezonedetect");
import { DateTime } from "luxon";

window.addEventListener("load", (event) => {
    if (document.querySelectorAll(".livewire-counter-container").length) {
        let counter = document.querySelectorAll(
            ".livewire-counter-container"
        )[0];
        let component = null;
        if (counter) {
            component = window.livewire.find(counter.getAttribute("wire:id"));
        }

        if (document.querySelector(".btn-primary-update-audience"))
            document
                .querySelector(".btn-primary-update-audience")
                .addEventListener("click", function (event) {
                    document.querySelector("#input-audience").value = parseInt(
                        component.get("audience")
                    );
                });

        if (document.querySelector("#audienceModal .btn-primary"))
            document
                .querySelector("#audienceModal .btn-primary")
                .addEventListener("click", function (event) {
                    component.set(
                        "audience",
                        parseInt(
                            document.querySelector("#input-audience").value
                        )
                    );
                });
    }
    if (document.querySelector(".chat__messages")) {
        var pauseScroll = false;
        document
            .querySelector(".chat__messages")
            .addEventListener("mouseenter", function (e) {
                pauseScroll = true;
            });
        document
            .querySelector(".chat__messages")
            .addEventListener("mouseleave", function (e) {
                pauseScroll = false;
            });
        setInterval(function () {
            if (!pauseScroll)
                document.querySelector(".chat__messages_content").scrollTop =
                    document.querySelector(
                        ".chat__messages_content"
                    ).scrollHeight;
        }, 250);
    }

    var myModal = document.getElementById("testStreamModal");
    if (myModal) {
        myModal.addEventListener(
            "hide.bs.modal",
            function (event) {
                // do something when this event triggers
                // event.target is the modal referenced in myModal
                jwplayer("testStream").stop();
            },
            false
        );
    }

    const timezone = jstz.determine();
    const timezone_code = new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];

    const datetimes = document.querySelectorAll(".datetime[data-datetime]");

    datetimes.forEach((item) => {
        const date = DateTime.fromISO(item.dataset.datetime);
        const elts = item.querySelectorAll("[data-format]");
        elts.forEach((elt) => {
            elt.innerHTML = date
                .setZone(timezone.name())
                .setLocale(navigator.language)
                .toLocaleString(JSON.parse(elt.dataset.format));
        });
    });

    const timezones = document.querySelectorAll(".timezone");
    timezones.forEach((tz) => {
        tz.innerHTML =
            "(time zone " +
            [
                timezone_code,
                // timezone.name()
            ].join(" ") +
            ")";
    });

    const start_at = document.querySelector("[data-datetime_start]");
    const end_at = document.querySelector("[data-datetime_end]");
    if (start_at && end_at) {
        const date_start_at = DateTime.fromISO(start_at.dataset.datetime_start);
        const date_end_at = DateTime.fromISO(start_at.dataset.datetime_end);

        function checkIsLive() {
            const isLive =
                DateTime.now().setZone(timezone.name()) >
                    date_start_at.setZone(timezone.name()) &&
                DateTime.now().setZone(timezone.name()) <
                    date_end_at.setZone(timezone.name());
            document.querySelectorAll(".isLive").forEach((item) => {
                if (isLive) {
                    item.classList.remove("isLiveHidden");
                } else {
                    item.classList.add("isLiveHidden");
                }
            });
        }

        checkIsLive();
        setInterval(checkIsLive, 1000 * 60);
    }
    if (document.querySelector(".new-live-practices")) {
        document.addEventListener("DOMContentLoaded", () => {
            Livewire.hook("element.updated", (el, component) => {
                console.log("Element updated", el, component);
            });
        });
        const updateLivePeriod = () => {
            const all_periods = {
                morning: "00|01|02|03|04|05|06|07|08|09",
                noon: "10|11|12|13",
                afternoon: "14|15|16|17|18",
                evening: "19|20|21|22|23",
            };
            const periods = Array.from(
                document.querySelectorAll(".period-selector input:checked")
            ).map((el) => el.value);
            const regexp =
                "T(" +
                periods.map((period) => all_periods[period]).join("|") +
                "):";
            document.querySelectorAll(".upcoming-event").forEach((el) => {
                if (periods.length == 0) {
                    el.classList.toggle("visible", true);
                } else {
                    const date = DateTime.fromISO(el.dataset.published_at);
                    const local_date = date
                        .setZone(timezone.name())
                        .setLocale(navigator.language);
                    const show =
                        periods.length == 0 ||
                        local_date.toISO().match(new RegExp(regexp));
                    el.classList.toggle("visible", show);
                }
            });
            toggleEventDates();
        };
        document.querySelectorAll(".period-selector input").forEach((el) => {
            el.addEventListener("change", updateLivePeriod);
        });

        document
            .querySelectorAll(".upcoming-events .upcoming-event")
            .forEach((el) => {
                const date = DateTime.fromISO(el.dataset.unpublished_at);
                if (date < DateTime.now()) {
                    el.classList.toggle("visible", false);
                    el.classList.toggle("hidden", true);
                }
            });
    }
    const toggleEventDates = () => {
        // foreach visible event
        document
            .querySelectorAll(
                ".upcoming-events.filtered .upcoming-event.visible"
            )
            .forEach((el) => {
                // find previous event with the class 'visible'
                let previous = el.previousElementSibling;
                while (previous && !previous.classList.contains("visible")) {
                    previous = previous.previousElementSibling;
                }
                // if found, compare the dates
                if (previous) {
                    const previous_date = DateTime.fromISO(
                        previous.dataset.published_at
                    );
                    const date = DateTime.fromISO(el.dataset.published_at);
                    if (previous_date.hasSame(date, "day")) {
                        el.classList.add("same-day");
                    } else {
                        el.classList.remove("same-day");
                    }
                } else {
                    el.classList.remove("same-day");
                }
            });
    };
    toggleEventDates();
});

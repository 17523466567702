import BSN from "bootstrap.native";

if (document.querySelector(".vajrayana-access-form"))
    document
        .querySelectorAll(".vajrayana-access-form button.btn-cta")
        .forEach((elt) => {
            elt.addEventListener("click", (e) => {
                document.getElementById("path_level_focus-D").value =
                    e.target.dataset.pathLevel;
                document.getElementById("path_level_focus-D").checked = true;
            });
        });

if (document.querySelector(".empowerment_show_contact")) {
    const toggleContact = (value) => {
        document
            .querySelector(".contact_shown_by_empowerment")
            .classList.toggle("hidden", value != "not_sure");
    };
    toggleContact(document.querySelector(".empowerment_show_contact").value);
    document
        .querySelector(".empowerment_show_contact")
        .addEventListener("change", (e) => toggleContact(e.target.value));
}

if (document.querySelector("body.settings")) {
    if (document.querySelector("#EmpowermentsModal")) {
        const modal = new BSN.Modal(
            document.getElementById("EmpowermentsModal")
        );
        if (modal) {
            document
                .getElementById("confirm_high_tantras")
                .addEventListener("click", (e) => {
                    document.getElementById("empowerments-high_tantras").value =
                        "yes";
                    modal.hide();
                });
            document
                .getElementById("cancel_high_tantras")
                .addEventListener("click", (e) => {
                    document.getElementById("empowerments-high_tantras").value =
                        "no";
                    modal.hide();
                });
            document
                .getElementById("empowerments-high_tantras")
                .addEventListener("change", function (event) {
                    console.log(event.target.value);
                    if (event.target.value == "yes") modal.show();
                });
        }
    }
}

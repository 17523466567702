document.querySelector(".search-input").addEventListener("blur", function () {
    setTimeout(function () {
        if (document.querySelector(".search-results-container"))
            document.querySelector(".search-results-container").style.display =
                "none";
    }, 250);
});
document.querySelector(".search-input").addEventListener("focus", function () {
    if (document.querySelector(".search-results-container"))
        document.querySelector(".search-results-container").style.display =
            "block";
});
if (document.querySelector(".search-results-container"))
    document.querySelector(".search-results-container").style.display = "none";

import Choices from "choices.js";
window.addEventListener("load", (event) => {
    const livewire_teachings_container = document.querySelector(
        ".livewire-teachings-container"
    );
    if (livewire_teachings_container) {
        const livewire_teachings = window.livewire.find(
            livewire_teachings_container.getAttribute("wire:id")
        );
        const queryString = new URLSearchParams(window.location.search);

        document.querySelectorAll(".select-filter").forEach((elt) => {
            let dropdown = new Choices(elt, {
                itemSelectText: "",
                searchEnabled: false,
                shouldSort: false,
            });
            dropdown.setChoiceByValue(queryString.get(elt.getAttribute("id")));
            dropdown.passedElement.element.addEventListener(
                "change",
                function (e) {
                    livewire_teachings.set(e.srcElement.id, e.detail.value);
                }
            );
        });

        // TOGGLE FILTERS
        if (document.querySelector(".bt-more-filters"))
            document
                .querySelector(".bt-more-filters")
                .addEventListener("click", function (event) {
                    this.classList.toggle("active");
                    document
                        .querySelector(".more-filters")
                        .classList.toggle("active");
                    event.preventDefault();
                });
    }
});

if (document.querySelector("#resetFilters")) {
    document
        .querySelector("#resetFilters")
        .addEventListener("click", function (event) {
            event.preventDefault();
            document.location.href = document.location.pathname;
        });
}

if (document.body.clientWidth <= 768) {
    document
        .querySelectorAll('[href="#previewContentModal"]')
        .forEach((elt) => {
            elt.href = elt.dataset.href;
        });
}

// setTimeout(() => {
//     console.log("teachings.js loaded");
//     document.querySelector('[href="#previewContentModal"').click();
// }, 1500);

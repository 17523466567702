import BSN from "bootstrap.native";

if (document.getElementById("previewContentModal")) {
    const modal = new BSN.Modal(document.getElementById("previewContentModal"));
    if (modal) {
        const closeModal = document.querySelectorAll(
            "#previewContentModal .close"
        );

        setInterval(function () {
            const showModal = document.querySelectorAll(
                "[href='#previewContentModal']:not(.haslistener)"
            );
            showModal.forEach((open) => {
                open.addEventListener("click", function (e) {
                    e.preventDefault();
                    document.querySelector(
                        "#previewContentModal .modal-body"
                    ).innerHTML = document.querySelector(
                        ".preview-content-modal-body_" +
                            e.target.dataset.itemUuid
                    ).innerHTML;
                    modal.show();
                });
                open.classList.add("haslistener");
            });
        }, 500);

        closeModal.forEach((close) => {
            close.addEventListener("click", function () {
                modal.hide();
            });
        });

        // modal.show();
    }
}

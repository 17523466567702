import BSN from "bootstrap.native";

Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
    (tip) => {
        const tooltip = BSN.Tooltip.getInstance(tip);
        tip.addEventListener(
            "shown.bs.tooltip",
            (event) => {
                if (parseInt(tooltip.tooltip.style.top) < 0)
                    tooltip.tooltip.style.top = 0;
                document
                    .querySelector("body")
                    .addEventListener("click", (e) => tooltip.toggle(), {
                        once: true,
                    });
            },
            false
        );
    }
);

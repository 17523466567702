import Glide, {
    Anchors,
    Breakpoints,
    Controls,
    Autoplay,
    Images,
    Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";

const slider_name = "same_stage";

function mountGlide() {
    if (document.querySelector(".glide-" + slider_name)) {
        let glide = new Glide(".glide-" + slider_name, {
            type: "slider",
            // autoplay: 4000,
            hoverpause: true,
            throttle: 250,
            bound: true,
            peek: { before: 0, after: 250 },
            breakpoints: {
                800: {
                    perView: 3,
                },
                1200: {
                    perView: 3,
                },
                1600: {
                    perView: 4,
                },
                2000: {
                    perView: 5,
                },
                8000: {
                    perView: 5,
                },
            },
            perView: 10, // max
        });
        glide.mount({
            Anchors,
            Autoplay,
            Breakpoints,
            Controls,
            Images,
            Swipe,
        });
    }
}

mountGlide();

window.Livewire.hook("message.processed", (message, component) => {
    if (component.name == "slider" && component.data.name == slider_name)
        mountGlide();
});

import BSN from "bootstrap.native";
import Cookies from "js-cookie";

if (document.getElementById("MoodleModal")) {
    const modal = new BSN.Modal(document.getElementById("MoodleModal"));
    if (modal) {
        const closeModal = document.querySelector("#MoodleModal .close");
        const openModal = document.querySelector("#openMoodleModal");

        closeModal.addEventListener("click", function () {
            modal.hide();
        });

        openModal.addEventListener("click", function () {
            modal.show();
        });

        // modal.show();
    }
}

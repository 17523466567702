//
// decoratorsBeforeExport: false

// var bsn = require("bootstrap.native/dist/bootstrap-native-v4");
import BSN from "bootstrap.native";
import { Workbox } from "workbox-window";
import Alpine from "alpinejs";

// window.Alpine = Alpine;
// Alpine.start();

// require("./sentry");
// require("./exceptionless");

require("./jwplayer");
require("./youtube-player");

require("./register");
require("./home");
require("./teachings");
require("./latests");
require("./same_stage");
require("./same_topic");
require("./compilation");
require("./language-selector");
require("./utilities");
require("./faq");
require("./live-stream");
require("./single-video");
require("./stripe");
require("./comments");
require("./search");
require("./materials");
require("./tooltip");
require("./modal");
require("./share");
require("./content-preview");
require("./feedback");
require("./external-links");
require("./alert");
require("./content");
require("./purchase");
require("./guides");
require("./account");
require("./newsletter-modal");
require("./moodle-modal");

// Alpine.discoverUninitializedComponents(function (el) {
//     Alpine.initializeComponent(el);
// });

if ("serviceWorker" in navigator) {
    const wb = new Workbox("/service-worker.js");
    wb.register();
}

import BSN from "bootstrap.native";
import Cookies from "js-cookie";

if (document.getElementById("NewsletterModal")) {
    const modal = new BSN.Modal(document.getElementById("NewsletterModal"));
    if (modal) {
        const closeModal = document.querySelector("#NewsletterModal .close");
        const newsletter_campaign =
            document.getElementById("NewsletterModal").dataset
                .newsletter_campaign;
        setInterval(function () {
            if (!Cookies.get("newsletterModal_" + newsletter_campaign)) {
                if (
                    !Cookies.get(
                        "newsletterModalSessionStart_" + newsletter_campaign
                    )
                )
                    Cookies.set(
                        "newsletterModalSessionStart_" + newsletter_campaign,
                        Date.now()
                    );
                if (
                    Date.now() >
                    parseInt(
                        Cookies.get(
                            "newsletterModalSessionStart_" + newsletter_campaign
                        )
                    ) +
                        1000 * 30
                ) {
                    modal.show();
                    Cookies.set("newsletterModal_" + newsletter_campaign, true);
                }
            }
        }, 2500);

        closeModal.addEventListener("click", function () {
            modal.hide();
        });

        // modal.show();
    }
}

import Glide, {
    Anchors,
    Controls,
    Breakpoints,
    Autoplay,
    Images,
    Swipe
} from "@glidejs/glide/dist/glide.modular.esm";
import {
    siblings
} from "@glidejs/glide/src/utils/dom";

const CustomActiveClass = function (Glide, Components, Events) {
    var Component = {
        mount() {
            this.setGlideState();
            this.changeActiveSlide();
        },

        changeActiveSlide() {
            // if (Glide.disabled) return;
            if (document.querySelector("body").clientWidth < 768) return;

            // document.querySelector(".glide__slides").style["margin-left"] =
            //     (Glide.index > 1 ? (Glide.index - 1) * 50 : 0) + "px";

            let slide = Components.Html.slides[Glide.index];
            slide.classList.remove(
                "is-next",
                "is-prev",
                "is-last",
                "is-predecessor",
                "is-successor"
            );
            slide.classList.add("is-active");

            siblings(slide).forEach(sibling => {
                sibling.classList.remove(
                    "is-active",
                    "is-next",
                    "is-prev",
                    "is-last",
                    "is-predecessor",
                    "is-successor"
                );
            });

            if (slide.nextElementSibling) {
                slide.nextElementSibling.classList.add("is-next");
            }

            if (slide.previousElementSibling) {
                slide.previousElementSibling.classList.add("is-prev");
            }
            let next = slide.nextElementSibling,
                prev = slide.previousElementSibling,
                idx;
            idx = 0;
            while (next) {
                next.classList.add("is-successor");
                if (idx++ >= Glide.settings.perView-1)
                    next.classList.add("is-last");
                next = next.nextElementSibling;
            }
        },

        setGlideState() {
            if (document.querySelector("body").clientWidth < 768) {
                Glide.disable();
                Components.Html.root.classList.remove("glide__enabled");
                for(const slide of document.querySelectorAll(".glide__slide--clone")){
                    slide.style.display = 'none';
                }
            } else {
                Glide.enable();
                Components.Html.root.classList.add("glide__enabled");
                for(const slide of document.querySelectorAll(".glide__slide--clone")){
                    slide.style.display = 'list-item';
                }
                // document.querySelector(".glide__slides").style[
                //     "margin-left"
                // ] = 0;
            }
            Component.changeActiveSlide();
        }
    };

    Events.on("run", () => {
        Component.changeActiveSlide();
    });

    Events.on("resize", () => {
        Component.setGlideState();
    });

    return Component;
};

if (document.querySelector(".glide-materials") && document.querySelectorAll('.material_list-item').length > 3) {
    new Glide(".glide-materials", {
        type: "carousel",
        breakpoints: {
            1000: {
                perView: 2
            },
            1200: {
                perView: 3
            },
            1600: {
                perView: 4
            },
            2000: {
                perView: 5
            },
            8000: {
                perView: 5
            }
        },
        perView: 6,
        throttle: 100,
        bound: true,
        peek: {
            before: 0,
            after: 250
        },
    }).mount({
        CustomActiveClass: CustomActiveClass,
        Anchors,
        Autoplay,
        Breakpoints,
        Controls,
        Images,
        Swipe
    });
}

import Glide, {
    Anchors,
    Controls,
    Autoplay,
    Images,
    Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";
import { siblings } from "@glidejs/glide/src/utils/dom";

import Splide from "@splidejs/splide";

let start = 0,
    current;
if (
    (current = document.querySelector(
        ".compilation-sidebar .splide .splide__slide--active"
    ))
)
    start = [...current.parentElement.children].indexOf(current);

if (document.querySelector(".compilation-sidebar .splide"))
    new Splide(".compilation-sidebar .splide", {
        start: start,
        drag: "free",
        direction: "ttb",
        height: "calc(100vw / 2.66)",
        autoHeight: true,
        heightRatio: 2.1,
        pagination: false,
        wheel: true,
        waitForTransition: true,
        releaseWheel: window.innerWidth < 640,
        wheelSleep: 100,
        wheelMinThreshold: 5,
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
        cover: true,
        pagination: true,
        focus: "start",
        breakpoints: {
            640: {
                height: "60vh",
            },
        },
    }).mount();

if (document.querySelector(".video-compilation-actions .splide"))
    new Splide(".video-compilation-actions .splide", {
        type: "loop",
        perPage: 3,
        drag: "free",
        speed: 600,
        pagination: false,
        arrows: false,
        autoWidth: true,
        gap: "1rem",
        autoplay: true,
    }).mount();

/*

old compilation slider

*/

const CustomActiveClass = function (Glide, Components, Events) {
    var Component = {
        mount() {
            this.setGlideState();
            this.changeActiveSlide();
        },

        changeActiveSlide() {
            // if (Glide.disabled) return;
            if (document.querySelector("body").clientWidth < 768) return;

            document.querySelector(".glide__slides").style["margin-left"] =
                (Glide.index > 1 ? (Glide.index - 1) * 50 : 0) + "px";

            let slide = Components.Html.slides[Glide.index];
            slide.classList.remove(
                "is-next",
                "is-prev",
                "is-predecessor",
                "is-successor"
            );
            slide.classList.add("is-active");

            siblings(slide).forEach((sibling) => {
                sibling.classList.remove(
                    "is-active",
                    "is-next",
                    "is-prev",
                    "is-predecessor",
                    "is-successor"
                );
            });

            if (slide.nextElementSibling) {
                slide.nextElementSibling.classList.add("is-next");
            }

            if (slide.previousElementSibling) {
                slide.previousElementSibling.classList.add("is-prev");
            }
            let next = slide.nextElementSibling,
                prev = slide.previousElementSibling,
                idx = 10;

            while (next) {
                next.classList.add("is-successor");
                next = next.nextElementSibling;
            }
            while (prev) {
                prev.classList.add("is-predecessor");
                prev = prev.previousElementSibling;
            }
        },

        setGlideState() {
            if (document.querySelector("body").clientWidth < 768) {
                Glide.disable();
                Components.Html.root.classList.remove("glide__enabled");
            } else {
                Glide.enable();
                Components.Html.root.classList.add("glide__enabled");
                document.querySelector(".glide__slides").style[
                    "margin-left"
                ] = 0;
            }
        },
    };

    Events.on("run", () => {
        Component.changeActiveSlide();
    });

    Events.on("resize", () => {
        Component.setGlideState();
    });

    return Component;
};

function mountGlide() {
    if (document.querySelector(".glide-compilation")) {
        var items = Array.prototype.slice.call(
            document.querySelector(".glide-compilation .glide__slides").children
        );
        var current = document.querySelector(
            ".glide-compilation .glide__slides .glide__slide--active"
        );

        let glide = new Glide(".glide-compilation", {
            type: "slider",
            perView: 4,
            throttle: 100,
            focusAt: "center",
            startAt: current ? items.indexOf(current) : 0,
        });
        glide.mount({
            CustomActiveClass: CustomActiveClass,
            Anchors,
            Autoplay,
            Controls,
            Images,
            Swipe,
        });
    }
}

mountGlide();

import BSN from "bootstrap.native";

if (document.getElementById("PopupAccessModal")) {
    const modal = new BSN.Modal(document.getElementById("PopupAccessModal"));
    if (modal) {
        modal.show();
    }
}

// FAQ

document.querySelectorAll(".accordion-heading").forEach((heading) => {
    heading.addEventListener(
        "click",
        (e) => {
            const open =
                e.target.parentNode.classList.contains("accordion--open");
            document.querySelectorAll(".accordion-item").forEach((item) => {
                item.classList.add("accordion--close");
                item.classList.remove("accordion--open");
            });
            if (!open) {
                history.pushState(null, null, "#" + e.target.id);

                e.target.parentNode.classList.add("accordion--open");
                e.target.parentNode.classList.remove("accordion--close");
            }

            window.scrollTo({
                left: 0,
                top: e.target.parentNode.offsetTop - 150,
                behavior: "smooth",
            });
        },
        false
    );
});

if (
    document.location.hash &&
    document.getElementById(document.location.hash.replace(/#/, ""))
) {
    document.getElementById(document.location.hash.replace(/#/, "")).click();
}

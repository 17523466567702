import BSN from "bootstrap.native";

if (document.getElementById("loginToAccessContentModal")) {
    const modal = new BSN.Modal(
        document.getElementById("loginToAccessContentModal")
    );
    if (modal) {
        const closeModal = document.querySelectorAll(
            "#loginToAccessContentModal .close"
        );

        setInterval(function () {
            const showModal = document.querySelectorAll(
                "[href='#loginToAccessContentModal']:not(.haslistener)"
            );
            showModal.forEach((open) => {
                open.addEventListener("click", function (e) {
                    e.preventDefault();
                    modal.show();
                });
                open.classList.add("haslistener");
            });
        }, 500);

        closeModal.forEach((close) => {
            close.addEventListener("click", function () {
                modal.hide();
            });
        });

        // modal.show();
    }
}

window.onYouTubeIframeAPIReady = function () {
    var video = document.querySelector(".yt-player");
    if (video) {
        // for live stream without a vidoe url yet
        let viewer = document.querySelector(".livewire-viewer-container");
        let component = null;
        if (viewer) {
            component = window.livewire.find(viewer.getAttribute("wire:id"));
        }
        const ytplayer = new YT.Player(video, {
            start: component ? component.get("elapsed") : 0,
            events: {
                onStateChange: function (e) {
                    switch (e.data) {
                        case 1:
                        case 3:
                            // playing
                            if (component) {
                                component.set("playing", true);
                            }
                            if (
                                document.querySelector(
                                    ".btn-primary-update-audience"
                                )
                            )
                                document
                                    .querySelector(
                                        ".btn-primary-update-audience"
                                    )
                                    .click();
                            document
                                .querySelector(".focus__inner")
                                .classList.add("hidden");
                            document
                                .querySelector(".focus__inner .bt-play")
                                .classList.remove("hidden");
                            break;
                        case 2:
                            // pause
                            if (component) {
                                component.set("playing", false);
                            }
                            document
                                .querySelector(".focus__inner")
                                .classList.remove("hidden");
                            break;
                    }
                },
            },
        });

        if (component) {
            setInterval(function () {
                if (ytplayer.getPlayerState() == 1) {
                    component.set(
                        "elapsed",
                        parseInt(ytplayer.getCurrentTime())
                    );
                }
            }, 2000);
        }
        if (document.querySelector(".focus__inner--single")) {
            document
                .querySelector(".focus__inner--single .container")
                .addEventListener("click", function (event) {
                    ytplayer.playVideo();
                    event.preventDefault();
                });
        }
    }
};

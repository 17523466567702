import { loadStripe } from "@stripe/stripe-js/pure";

async function initStripe() {
    // Stripe.js will not be loaded until `loadStripe` is called
    loadStripe.setLoadParameters({
        advancedFraudSignals: true,
    });
    const stripe = await loadStripe(
        document.getElementById("card-button").dataset.stripePk
    );
    startStripe();
}

async function startStripe() {
    const stripe = await loadStripe(
        document.getElementById("card-button").dataset.stripePk
    );

    const elements = stripe.elements();
    const cardElement = elements.create("card", { hideIcone: false });
    cardElement.mount("#card-element");
    const cardHolderName = document.getElementById("card-holder-name");
    const cardError = document.getElementById("card-errors");
    cardElement.addEventListener("change", function (event) {
        if (event.error) {
            cardError.textContent = event.error.message;
        } else {
            cardError.textContent = "";
        }
    });
    var form = document.getElementById("cashier-form");
    form.addEventListener("submit", async (e) => {
        e.preventDefault();
        if (
            (document.getElementById("invitation_code_valid") &&
                document.getElementById("invitation_code_valid").value !==
                    "false") ||
            !document.getElementById("card-holder-name") ||
            (document.getElementById("price") &&
                parseInt(document.getElementById("price").value) === 0)
        ) {
            form.submit();
        } else {
            document.getElementById("loading").style.display = "block";

            let error, payment_method_id, paymentIntent, setupIntent;

            if (document.querySelector(".purchase-pricing")) {
                // single charge
                let res = await stripe.confirmCardPayment(
                    document.getElementById("card-button").dataset.stripeSk,
                    {
                        payment_method: {
                            card: cardElement,
                            billing_details: {
                                name: cardHolderName.value,
                            },
                        },
                    }
                );
                paymentIntent = res.paymentIntent;
                error = res.error;
                if (paymentIntent) payment_method_id = paymentIntent.id;
            } else {
                // subscription
                let res = await stripe.confirmCardSetup(
                    document.getElementById("card-button").dataset.stripeSk,
                    {
                        payment_method: {
                            card: cardElement,
                            billing_details: {
                                name: cardHolderName.value,
                            },
                        },
                    }
                );
                setupIntent = res.setupIntent;
                error = res.error;
                payment_method_id = setupIntent.payment_method;
            }
            if (error) {
                // Display "error.message" to the user...
                console.log(error);
                document.getElementById("card-errors").innerHTML =
                    error.message;
                document.getElementById("loading").style.display = "none";
            } else {
                // The card has been verified successfully...
                var hiddenInput = document.createElement("input");
                hiddenInput.setAttribute("type", "hidden");
                hiddenInput.setAttribute("name", "payment_method");
                hiddenInput.setAttribute("value", payment_method_id);
                form.appendChild(hiddenInput);
                // Submit the form

                form.submit();
            }
        }
    });
}

if (document.querySelector("#card-element")) {
    initStripe();
}

// Selectionne le noeud dont les mutations seront observées
var targetNode = document.getElementById("payment_form");

if (targetNode) {
    // Options de l'observateur (quelles sont les mutations à observer)
    var config = { attributes: true, childList: true };

    // Fonction callback à éxécuter quand une mutation est observée
    var callback = function (mutationsList) {
        for (var mutation of mutationsList) {
            if (mutation.type == "childList") {
                console.log("Un noeud enfant a été ajouté ou supprimé.");
                if (document.querySelector("#card-element")) {
                    startStripe();
                }
            } else if (mutation.type == "attributes") {
                console.log(
                    "L'attribut '" + mutation.attributeName + "' a été modifié."
                );
            }
        }
    };

    // Créé une instance de l'observateur lié à la fonction de callback
    var observer = new MutationObserver(callback);

    // Commence à observer le noeud cible pour les mutations précédemment configurées
    observer.observe(targetNode, config);
}

import BSN from "bootstrap.native";
import Choices from "choices.js";

// Registration Type

if (document.querySelector("#registration-type")) {
    /*
    document
        .querySelector("#registration-type")
        .addEventListener("change", function(e) {
            showRegistrationForm(e.target.value);
        });

    showRegistrationForm(document.querySelector("#registration-type").value);
    */

    var selectType = document.getElementsByName("registration-type"),
        i;

    for (i = 0; i < selectType.length; i++) {
        selectType[i].addEventListener("click", function (e) {
            showRegistrationForm(e.target.value);
        });
    }
    if (document.querySelector("input[name=registration-type]:checked")) {
        showRegistrationForm(
            document.querySelector("input[name=registration-type]:checked")
                .value
        );
    }

    function showRegistrationForm(type) {
        document
            .querySelectorAll(".registration-form")
            .forEach(
                (item) =>
                    (item.style.display = item.classList.contains(
                        "registration-form-" + type
                    )
                        ? "block"
                        : "none")
            );
    }

    var selectFilter = document.getElementsByClassName("select-filter");
    if (selectFilter.length > 0) {
        document.querySelectorAll(".select-filter").forEach((elt) => {
            let dropdown = new Choices(elt, {
                itemSelectText: "",
                searchEnabled: false,
                shouldSort: false,
            });
        });
    }
}

function disableLink(link) {
    // 1. Add isDisabled class to parent span
    link.parentElement.classList.add("isDisabled");
    // 2. Store href so we can add it later
    link.setAttribute("data-href", link.href);
    // 3. Remove href
    link.href = "";
    // 4. Set aria-disabled to 'true'
    link.setAttribute("aria-disabled", "true");
}

if (document.querySelector("body.register-step-2, body.register-step-3")) {
    document
        .querySelector("#header")
        .addEventListener("click", function (event) {
            // filter out clicks on any other elements
            if (
                event.target.nodeName == "A" &&
                event.target.getAttribute("aria-disabled") == "true"
            ) {
                event.preventDefault();
            }
        });

    document.querySelectorAll("#header a").forEach((elt) => {
        disableLink(elt);
    });
    document
        .querySelectorAll("#header button, #header input")
        .forEach((elt) => {
            elt.parentElement.classList.add("isDisabled");
            elt.setAttribute("disabled", "disabled");
        });
}

if (document.querySelector("body.register-step-2")) {
    if (document.querySelector("#EmpowermentsModal")) {
        console.log("EmpowermentsModal");
        const modal = new BSN.Modal(
            document.getElementById("EmpowermentsModal")
        );
        if (modal) {
            document
                .getElementById("confirm_vajrayana")
                .addEventListener("click", (e) => {
                    modal.hide();
                });
            document
                .getElementById("cancel_vajrayana")
                .addEventListener("click", (e) => {
                    document.getElementById(
                        "path_level_focus-C"
                    ).checked = true;
                    modal.hide();
                });
            document
                .getElementById("path_level_focus-D")
                .addEventListener("change", function (event) {
                    console.log(event.target.value);
                    if (event.target.value == "D.2") modal.show();
                });
        }
    }

    /*
    if (document.getElementById("VajrayanaAccessModal")) {
        const modal = new BSN.Modal(
            document.getElementById("VajrayanaAccessModal")
        );
        if (modal) {
            const closeModal = document.querySelectorAll(
                "#VajrayanaAccessModal .close"
            );

            closeModal.forEach((close) => {
                close.addEventListener("click", function () {
                    modal.hide();
                });
            });

            // modal.show();

            document
                .querySelectorAll(
                    ".radio-item.next input, .radio-item.next label"
                )
                .forEach((elt) => {
                    elt.addEventListener("click", function (e) {
                        e.preventDefault();
                        modal.show();
                    });
                });

            document
                .querySelectorAll("#VajrayanaAccessModal button.btn-cta")
                .forEach((elt) => {
                    elt.addEventListener("click", (e) => {
                        document.getElementById("path_level_focus-D").value =
                            e.target.dataset.pathLevel;
                        document.getElementById(
                            "path_level_focus-D"
                        ).checked = true;
                        modal.hide();
                    });
                });

        }
    }
    */
}

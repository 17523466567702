// DETECT TOUCH DEVICE
var is_touch_device = function() {
    return (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
};
is_touch_device() ? document.documentElement.classList.add('touch') : document.documentElement.classList.add('no-touch');

// ADD OUTLINE ON KEYBOARD EVENTS ONLY
var styleFocusOnKeyboardEvents = function() {
    var container = document.querySelector('body');
    document.addEventListener('keyup', function(event) {
        if (container.contains(event.target)) {
            container.classList.add('has-keyboard-focus');
        } else {
            container.classList.remove('has-keyboard-focus');
        }
    });
    document.addEventListener('mousedown', function(event) {
        if (container.contains(event.target)) {
            container.classList.remove('has-keyboard-focus');
        }
    });
};

// WINDOW READY
document.addEventListener('DOMContentLoaded', function() {
    styleFocusOnKeyboardEvents();
});

// WINDOW LOAD
window.addEventListener('load', function(event) {
    document.querySelector('body').classList.add('loaded');

    // TOGGLE MOBILE NAV
    document
        .querySelector(".bt-menu")
        .addEventListener("click", function(event) {
            document.querySelector('body').classList.toggle('menu-open');
            document.querySelector('body').classList.toggle('menu-open--nav');
            if (document.getElementsByClassName('menu-open--search').length){
                document.querySelector('body').classList.toggle('menu-open--search');
                document.querySelector('body').classList.toggle('menu-open');
            }
            event.preventDefault();
        });

    document
        .querySelector(".bt-search")
        .addEventListener("click", function(event) {
            document.querySelector('body').classList.toggle('menu-open');
            document.querySelector('body').classList.toggle('menu-open--search');
            if (document.getElementsByClassName('menu-open--nav').length){
                document.querySelector('body').classList.toggle('menu-open--nav');
                document.querySelector('body').classList.toggle('menu-open');
            }
            event.preventDefault();
        });
});
